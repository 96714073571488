.consent-cookie-box {
  $root: &;
  background: $sandHoverColor;
  color: $textBaseColor;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $indexCookies;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / .5);
  min-height: 25%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &__inner-box {
    @include container();

    @media (min-width: $screen-desktop-sm) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__content {
    padding: 0;

    @media (min-width: $screen-tablet-portrait) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  &__headline {
    margin: 16px 0 8px;
    font-size: $fontSizeSmall;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $fontSizeXYMedium;
      margin-top: 48px;
    }
  }

  &__text {
    font-size: $fontSizeXSmall;
    line-height: 1.3;
    margin-bottom: 24px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $fontSizeSmall;
      padding-right: 20px;
      flex: 1 1 auto;
    }

    strong {
      font-weight: $fontWeightSemiBold;
    }

    a,
    a:visited {
      text-decoration: underline;
      white-space: nowrap;
      color: $textBaseColor;
    }
  }

  &__checkbox-container {
    display: flex;
    margin-bottom: 8px;
    flex-wrap: wrap;

    @media (min-width: $screen-mobile-landscape) {
      justify-content: space-between;
    }

    @media (min-width: $screen-laptop) {
      margin-bottom: 30px;
    }

    &--hide {
      display: none;
    }
  }

  &__checkbox {
    display: block;
    margin-bottom: 16px;
    width: 50%;

    @media (min-width: $screen-mobile-landscape) {
      width: auto;
    }

    @media (min-width: $screen-tablet-portrait) {
      width: 50%;
    }

    @media (min-width: $screen-laptop) {
      width: 46%;
    }

    @media (min-width: $screen-desktop) {
      width: auto;
    }

    .checkbox__label {
      position: relative;
      margin: 2px 0 0 3px;
    }
  }

  &__button-container {
    display: block;
    min-width: 300px;
  }

  &__button-selection,
  &__button-accept-all,
  &__button-reject-all,
  &__button-config {
    margin-bottom: 8px;
    width: 100%;

    @media (min-width: $screen-mobile-landscape) {
      padding: 0 24px;
    }
  }

  &__text-container {
    display: block;
    max-height: 40vh;
    overflow-y: auto;
    min-width: 280px;
  }

  &__button-selection {
    --button-primary-text-color: #{$orangeColor};
    --button-primary-background-color: #{transparent};
    --button-primary-border-color: #{$orangeColor};
    --button-primary-hover-background-color: #{transparent};
    --button-primary-hover-text-color: #{$orangeHoverColor};
    --button-primary-active-text-color: #{$orangeActiveColor};
    --button-primary-active-background-color: #{transparent};
    box-shadow: inset 0 0 0 1px $orangeColor;
    margin-bottom: 8px;
    letter-spacing: 1px;

    @media (min-width: $screen-tablet-xsm) {
      margin-right: 8px;
    }
  }

  &__button-reject-all {
    --button-secondary-text-color: #{$orangeColor};
    --button-secondary-background-color: #{transparent};
    --button-secondary-border-color: #{$orangeColor};
    --button-secondary-hover-background-color: #{transparent};
    --button-secondary-hover-text-color: #{$orangeHoverColor};
    --button-secondary-active-text-color: #{$orangeActiveColor};
    --button-secondary-active-background-color: #{transparent};
    box-shadow: inset 0 0 0 1px $orangeColor;
    margin-bottom: 8px;
    letter-spacing: 1px;

    @media (min-width: $screen-tablet-xsm) {
      margin-right: 8px;
    }
  }

  &__button-config {
    margin-bottom: 8px;
    text-decoration: underline;
    background: transparent;
    border-color: transparent;
    color: $textBaseColor;
    letter-spacing: 1px;
  }

  &--box {
    #{$root}--configuration {
      @media (min-width: $screen-tablet-portrait) {
        #{$root}__content {
          flex-direction: column;
        }

        #{$root}__col--flex {
          width: 100%;
        }

        .form {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

        #{$root}__checkbox-container {
          display: flex;
          margin-bottom: 8px;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 54%;
        }

        #{$root}__checkbox {
          align-self: center;
        }
      }
    }
  }

  div[data-placeholder="consent-cookie-box"] & {
    .loader {
      position: absolute;
      right: 0;
      left: 0;
      margin: auto;

      &::before,
      &::after {
        color: $brandPrimaryColor;
      }
    }
  }
}
