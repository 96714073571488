@import '../../../bps/components/consentCookieBox/consentCookieBoxLightBox.scss';

.consent-cookie-box-light-box {
  background: $sandHoverColor;

  &__logo-container {
    text-align: center;
    display: block;
    padding-top: 16px;

    @media (min-width: $screen-tablet-portrait) {
      text-align: left;
      padding-left: 24px;
      padding-top: 24px;
    }

    .logo {
      width: 95px;
      height: 55px;
    }
  }

  .consent-cookie-box {
    &__button-container {
      .button {
        border-radius: $buttonBorderRadius;

        @media (min-width: $screen-tablet-portrait) {
          width: auto;
          min-width: 245px;
          margin-bottom: 0;
        }
      }
    }

    &--with-reject-button {
      .button {
        @media (min-width: $screen-tablet-portrait) {
          min-width: 230px;
          margin-bottom: 0;
          padding: 0 12px 0 16px;
        }
      }
    }
  }

  div[data-placeholder="consent-cookie-box"] & {
    .loader {
      &::before,
      &::after {
        color: $textDarkestColor;
      }
    }

    .button--primary .loader {
      &::before,
      &::after {
        color: $whiteColor;
      }
    }
  }
}
